/* eslint-disable no-underscore-dangle */
import { graphql } from 'gatsby';
import React from 'react';
import { Freya } from '../src/components/Freya/Freya';
import Heimdall from '../src/components/Heimdall/Heimdall';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Saga } from '../src/components/Saga/Saga';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import SEO from '../src/helpers/seo';
import Layout from '../src/components/Layout/Layout';
import tt from '../src/helpers/translation';
import Buttons from '../src/components/Buttons/Buttons';
import './laliga_twenty_nines.scss';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';

const laligaTwentyNines = ({
  data: {
    allLaligaTwentyNinesSeoBlock,
    allLaligaTwentyNinesHeaderBlock,
    allLaligaTwentyNinesJumboImage,
    allLaligaTwentyNinesMobileImage,
    allLaligaTwentyNinesBreadcrumbBlock,
    allLaligaTwentyNinesSliderGalleryBlock,
    allLaligaTwentyNinesTextSagaBlock,
    allLaligaTwentyNinesSchedule,
    allLaligaTwentyNinesPromotionalBlock,
    // allLaligaTwentyNinesPromocionesSubBlock,
    allLaligaTwentyNinesButtonText,
  },
  pageContext: { locale, pageName, url, options },
}) => {
  const titleH3 = {
    title: tt('PROMOCIONES VIGENTES', locale),
  };

  const heimdallData = {
    name: allLaligaTwentyNinesHeaderBlock.edges[0].node.title,
    subtitle: allLaligaTwentyNinesHeaderBlock.edges[0].node.description,
    image: {
      url: allLaligaTwentyNinesJumboImage.edges[0].node.localImage.childImageSharp.fluid,
      alt: allLaligaTwentyNinesJumboImage.edges[0].node.alt,
    },
    imageMobile: {
      alt: allLaligaTwentyNinesMobileImage.edges[0].node.alt,
      url: allLaligaTwentyNinesMobileImage.edges[0].node.localImage.childImageSharp.fluid,
    },
  };

  // const freyaData = {
  //   cards: allLaligaTwentyNinesPromocionesSubBlock.edges.map((item) => ({
  //     image: item.node.localImage.childImageSharp.fluid,
  //     altImage: item.node.image_alt,
  //     title: item.node.title,
  //     imgsize: item.node.imgsize,
  //     twoButton: true,
  //     buttons: {
  //       cta: item.node.buttons.cta,
  //       size: 'small',
  //       color: item.node.buttons.color,
  //       ctaText: item.node.buttons.ctaText,
  //       cta2: item.node.buttons.cta2,
  //       size2: 'small',
  //       color2: 'orange',
  //       ctaText2: item.node.buttons.ctaText2,
  //     },
  //   })),
  // };

  const SagaData = {
    image: allLaligaTwentyNinesSliderGalleryBlock.edges[0].node.image,
    altImage: allLaligaTwentyNinesSliderGalleryBlock.edges[0].node.image_alt,
    title: allLaligaTwentyNinesTextSagaBlock.edges[0].node.title,
    textBox: allLaligaTwentyNinesPromotionalBlock.edges[0].node.textBox,
    summary:
      allLaligaTwentyNinesTextSagaBlock.edges[0].node.summary +
      allLaligaTwentyNinesTextSagaBlock.edges[0].node.summary2,
    social: false,
    schudle: allLaligaTwentyNinesSchedule.edges[0].node.body,
    button1: {
      cta: allLaligaTwentyNinesPromotionalBlock.edges[0].node.call_to_action_path,
      size: 'alone',
      blank: allLaligaTwentyNinesPromotionalBlock.edges[0].node.blank,
      color: 'orange',
      ctaText: allLaligaTwentyNinesPromotionalBlock.edges[0].node.call_to_action_copy,
    },
    buttonPDF: {
      cta: allLaligaTwentyNinesPromotionalBlock.edges[0].node.cta2,
      size: 'alone',
      color: 'orange',
      ctaText: allLaligaTwentyNinesPromotionalBlock.edges[0].node.ctaText2,
    },
  };

  return (
    <Layout locale={locale} pageName={pageName} options={options} layoutFromOld>
      <SEO
        title={allLaligaTwentyNinesSeoBlock.edges[0].node._0.title}
        description={allLaligaTwentyNinesSeoBlock.edges[0].node._1.description}
        pathname={url}
        lang={locale}
      />
      <div className="is-fullWidth">
        <Heimdall data={heimdallData} />
        <div className="general-index">
          <div className="shop-details-content">
            <div className="top-shop-details">
              <NeoAesir
                locale={locale}
                pageName={pageName}
                title={allLaligaTwentyNinesBreadcrumbBlock.edges[0].node.name}
              />
            </div>
            <BlueArrow />
            <Saga data={SagaData} />
            <div className="container-mapButton">
              <Buttons
                className="button-map"
                size="alone"
                link={allLaligaTwentyNinesButtonText.edges[0].node.cta}
                color="orange"
                blank="true"
                text={allLaligaTwentyNinesButtonText.edges[0].node.cta_text}
              />
            </div>
            {/* <div className="promotion-content">
              <H3 data={titleH3} />
              <Freya data={freyaData} />
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default laligaTwentyNines;
export const pageQuery = () => graphql`
  query QueryLaligaTwentyNines($locale: String!) {
    allLaligaTwentyNinesSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allLaligaTwentyNinesHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allLaligaTwentyNinesJumboImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image
        }
      }
    }
    allLaligaTwentyNinesSliderGalleryBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          image
          image_alt
        }
      }
    }
    allLaligaTwentyNinesButtonText(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          cta
          cta_text
        }
      }
    }
    allLaligaTwentyNinesTextSagaBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          summary
          summary2
          ctaText1
          cta1
          body
        }
      }
    }
    allLaligaTwentyNinesSchedule(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          body
        }
      }
    }
    allLaligaTwentyNinesPromotionalBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          tag
          call_to_action_copy
          call_to_action_path
          blank
          textBox
          cta2
          ctaText2
        }
      }
    }
    allLaligaTwentyNinesMobileImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allLaligaTwentyNinesBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
  }
`;
